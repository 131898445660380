<script setup lang="ts">
  const isNavOpen = ref(false)

const handleNavToggle = (isOpen: boolean) => {
    isNavOpen.value = isOpen
  };
</script>

<template>
  <div class="bg-background">
    <!-- <SlLayoutTopBar /> -->
    <SlLayoutSideNavDual @toggle="handleNavToggle" />
    <div
      class="transition-all duration-300 bg-background"
      :class="{ 'ml-80': isNavOpen, 'ml-24': !isNavOpen }"
      style="height: calc(100vh - 4rem); z-index: 30; position: relative;"
    >
      <div class="bg-background">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>
